import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Routes, Link} from 'react-router-dom';
import Mainpage from './components/mainpage/Mainpage'
import logo from './static/images/app/logowhite.png'


const App = () => {
    const [submenu, setSubmenu] = useState(false);
    const cls = ["navbar__menu__submenu"];
    const [headerState, setHeaderState] = useState(false)
    const [menuToggleChecked, setMenuToggleChecked] = useState(false);
    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    // a button "up" which appears when user scrolls down
    const addScroll = () => {
        window.onscroll = function () {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                document.getElementById("myBtn").style.display = "block";
            } else {
                document.getElementById("myBtn").style.display = "none";
            }
        };
    };

    useEffect(() => {
        addScroll();
        document.addEventListener('scroll', addScroll);
        return () => {
            document.removeEventListener('scroll', addScroll);
        };
    }, []);

    const handlerClick = () => {
        setSubmenu(!submenu);
    };

    const handlerClose = () => {
        setSubmenu(false);
    };

    const headerHandlerClose = () => {
        setHeaderState(false);
        setMenuToggleChecked(false);
    };

    const headerButtonSwitch = () => {
        setHeaderState(!headerState)
        if(headerState){
            setMenuToggleChecked(false)
        } else {
            setMenuToggleChecked(true)
        }
    }

    return (
        <div className="App">
            <div className="navbar">
                <div className="container__fixed layout header">
                    <Link className="navbar__logo" to="/"><img src={logo} alt=""/></Link>

                    <div className="hamburger-menu">
                        <input id="menu__toggle" type="checkbox" checked={menuToggleChecked} onClick={headerButtonSwitch}/>
                        <label className="menu__btn" htmlFor="menu__toggle">
                            <span></span>
                        </label>
                        {headerState ? (
                            <ul className="menu__box" id="menu__box">
                                <li className="nav__item"><a href="#about__scroll" onClick={headerHandlerClose}>About bot</a></li>
                                <li className="nav__item"><a href="#service__scroll" onClick={headerHandlerClose}>Why Swift Crypto Exchange</a></li>
                                <li className="nav__item"><a href="#benefits__scroll" onClick={headerHandlerClose}>How to use bot</a></li>
                                <li className="nav__item"><a href="#contacts__scroll" onClick={headerHandlerClose}>Contacts</a></li>
                            </ul>) : null}

                    </div>

                    <ul className="navbar__menu layout">
                        <li onClick={handlerClick} id="submenuparent">
                            <Link to="/">About</Link>
                            {submenu ? (
                                <ul className={cls.join(' ')}>
                                    <li className="submenu__item"><a href="#about__scroll">About bot</a></li>
                                    <li className="submenu__item"><a href="#service__scroll">Why Swift Crypto Exchange</a></li>
                                    <li className="submenu__item"><a href="#benefits__scroll">How to use bot</a></li>
                                    <li className="submenu__item"><a href="#contacts__scroll">Contacts</a></li>
                                </ul>
                            ) : null}
                        </li>
                    </ul>
                </div>
            </div>

            <Routes>
                <Route
                    path="/"
                    element={<Mainpage Message="foooooo" handlerClose={handlerClose} />}
                />
            </Routes>

            <button onClick={topFunction} id="myBtn" title="Go to top">
                <i className="fas fa-arrow-up"></i>
            </button>
        </div>
    );
};

export default App;
