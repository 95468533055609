import React from 'react'
import './Contacts.css'
import email from '../../../static/images/contacts/email.png';
import telegram from '../../../static/images/contacts/telegram.png';

const Contacts  = props =>{
  return(
    <div className = 'Contacts'>
      <h2 className = "contacts__header">Swift Crypto Exchange bot</h2>
        <div className = "contacts__container container__fixed layout">
            <div className = "contacts__container__block">
              <a title="Telegram" href="https://t.me/SwiftyCryptoExBot">
                <img src={telegram} className="contacts__icon" alt="telegram"/>
              </a>
              <br/><br/>
              <a href='https://t.me/cryptoexch_node_bot'>Make an exchange</a>
            </div>

        </div>
      </div>
    )
  }

  export default Contacts
