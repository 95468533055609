import React from 'react';
import Firstscreen from '../app/firstscreen/Firstscreen'
import About from '../app/about/About'
import Contacts from '../app/contacts/Contacts'
import HowToUse from "../app/howtouse/howToUse";
import Advantages from "../app/advantages/advantages";

export default function Mainpage(props) {
  return(

      <div className="Mainpage" onClick={props.handlerClose}>
        <Firstscreen handlerClose={props.handlerClose}/>
        <div id='about__scroll'></div>
        <About/>
        <div id='service__scroll'></div>
        <Advantages/>
        <div id='benefits__scroll'></div>
        <HowToUse/>
        <div id='contacts__scroll'></div>
        <Contacts/>
      </div>
  );
}
