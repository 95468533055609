import React, {useState} from 'react'
import './Firstscreen.css'

export default function Firstscreen(props){
    const [state, setState] = useState()

    return(
        <div className = 'firstScreen' onClick={props.handlerClose} >
            <h1 className='firstScreen__header'>Swift Crypto Exchange Bot</h1>
            <h1 className='firstScreen__header'>Where Speed Meets Security.</h1>
            <a href="#contacts__scroll" onClick={props.handlerClose} className='btn btn_orange '>Make your first exchange</a>
            <label>
                <a href="#about__scroll" ><i className="fas fa-chevron-down"></i></a>
            </label>
        </div>
    )
}
