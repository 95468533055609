import React from 'react'
import './advantages.css'
import service_img from '../../../static/images/advantages/service_img.jpg';

const Advantages  = props =>{
  return(
    <div className = 'Service'>
      <div className = "container__fixed layout_big">

        <div className="service__side service__side__img">
            <img src={service_img} className="service_img" alt="service_img"/>
        </div>

        <div className = "service__side service__side__description">
            <h2>Why Swift Crypto Exchange?</h2>
              <p><i className="fa fa-check"></i>User-Friendly: Swift Crypto Exchange Bot offers a straightforward and user-friendly interface.</p>
              <p><i className="fa fa-check"></i>Speed: Experience lightning-fast order processing for quick transactions.</p>
              <p><i className="fa fa-check"></i>Security: Rest easy knowing that your transactions are highly secure and reliable.</p>
              <p><i className="fa fa-check"></i>Professional Team: Our dedicated team of experts ensures a high-quality product.</p>
              <p><i className="fa fa-check"></i>Constant Improvement: We're committed to daily product enhancements for an even better experience.</p>
              <p><i className="fa fa-check"></i>Simplified Cryptocurrency Purchases: Easily buy cryptocurrency with convenience and confidence.</p>
        </div>


        </div>
      </div>
    )
  }

  export default Advantages
