import React from 'react'
import './About.css'
import about1 from '../../../static/images/about/about1.jpg';
import about2 from '../../../static/images/about/about2.jpg';
import about3 from '../../../static/images/about/about3.png';
import about4 from '../../../static/images/about/about4.jpeg';

const About = props =>{
  return(
    <div className = 'About'>
      <div className = "container__fixed layout_big">
        <div className="about__side">
        <h2>About bot</h2>

        <p>Swift Crypto Exchange bot is an automatic agent that connects seller and customer in buying cryptocurrencies<br/><br/>
        Our goal is to make crypto exchange the easiest thing.<br/><br/>
            Introducing Swift Crypto Exchange Bot, the Telegram bot that sets itself apart from other cryptocurrency traders. What makes it stand out is its remarkable speed and reliability in processing orders, ensuring a seamless and secure experience for users. <br/><br/>
            With a dedicated team of professionals committed to delivering a high-quality product, Swift Crypto Exchange Bot is designed for user convenience and exceptional results in a simple cryptocurrency purchases.<br/><br/>
            Our team is continuously working to enhance the product, striving to make it better every day to provide users with the best possible experience.
        </p>
        </div>
        <div className="about__side">
          <div className="about__side__img" >
                <img src={about1} className="about_img" alt="about"/>
                <img src={about2} className="about_img" alt="about"/>
          </div>
          <div className="about__side__img">
                <img src={about3} className="about_img" alt="about"/>
                <img src={about4} className="about_img" alt="about"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
