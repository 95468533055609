import React from 'react'
import './HowToUse.css'

const HowToUse  = props =>{
  return(
      <div className="howto_container">
          <div className="howto_text_container">
              <h1 className="howto_main_header">How to use bot?</h1>
              <h2 className="howto_secondary_header">1. Installation</h2>
              <p className="howto_p">Launch the bot, it will seamlessly register you, granting you the freedom to tailor your experience to your preferences. Choose your language, select the currencies you wish to transact with, and make an exchange</p>
              <h2 className="howto_secondary_header">2. Exchange</h2>
              <p className="howto_p">Initiate the exchange process by clicking on 'buy' or 'sell' button. A few simple steps, and our swift operator will promptly handle your request. Once completed, you'll receive a notification that your funds have been transferred to the specified wallet.</p>
          </div>

      </div>
  )
}

  export default HowToUse
